<template>
    <v-dialog v-model="open"  @click:outside="close" max-width="600">
      <v-card class="">

        <div class="text-center text-h6 primary white--text">
        <div>

        {{ $t("message.promotionDialog.title") }}
      </div>
      </div>
        <v-card-actions class="layout justify-center">
          <promotion-stickers :display_price="false" :display_promotion_label="false" :logo_on_top="true" @onClose="onCloseBtn"> </promotion-stickers>
        </v-card-actions>

        <div class="text-center pb-3">
        <v-btn fab small @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      </v-card>


    </v-dialog>
  </template>

<style scoped>
.close-button {
  margin-right: auto; /* Pushes the button to the left */
}
</style>

  <script>
  import { mapGetters } from "vuex";
  import PromotionStickers from "Components/Widgets/PromotionStickers.vue";
  export default {
    computed: {
      ...mapGetters(["session_warning"]),
    },
    props: [],
    components: { PromotionStickers },
    data() {
      return {
        open: false,
        message: "",
      };
    },
    mounted() {
    },
    methods: {
      openDialog() {
        this.open = true;
      },
      close() {
        this.open = false;
      },
      onCloseBtn() {
        this.close();
      }
    },
  };
  </script>
  
  