<template>
  <div class="emb-feature-wrap">
    <div
      class="container emb-card amber lighten-5 mt-3"
      v-if="getUserNewNotifications.length > 0 && notifications_visible == true"
    >
      <div class="d-flex justify-space-between align-center">
        <v-icon large>mdi-bell</v-icon>

        <span class="ma-4">
          <router-link to=""
            ><p @click="openMessages()">{{ getMessage() }}</p>
          </router-link></span
        >
        <v-btn icon @click="notifications_visible = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog
      transition="dialog-top-transition"
      max-width="500"
      v-model="dialog"
    >
      <template>
        <v-card>
          <v-toolbar color="primary" dark>{{
            $t("message.notifications.header")
          }}</v-toolbar>
          <v-card-text>
            <v-list two-line>
              <div
                v-for="(notification, index) in getUserNewNotifications"
                :key="index"
              >
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon large>{{
                      $getNotificationIcon(notification)
                    }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <p class="px-6">
                      {{ $getNotificationMessage(notification) }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index != getUserNewNotifications.length - 1"
                ></v-divider>
              </div>
            </v-list>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="closeDialog()">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
// import api from "Api";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getUserNewNotifications"]),
  },
  data() {
    return {
      notifications_visible: true,
      dialog: false,
    };
  },
  methods: {
    openMessages() {
      console.log("test patate");
      this.dialog = true;
    },
    getMessage() {
      var user_win = false;
      for (var notification in this.getUserNewNotifications) {
        if (notification.type == 1) {
          user_win = true;
          break;
        }
      }

      if (user_win) {
        return this.$t("message.notifications.bannerMsg.winner");
      } else {
        return this.$t("message.notifications.bannerMsg.genericMessage");
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$store.dispatch("set_new_notifications_read");
    },
  },
};
</script>
