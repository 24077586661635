<template>
  <div class="emb-aside-banner-v1">
    <div v-if="can_buy">
      <router-link :to="'/lotteries/' + type">
        <div
          class="emb-card"
          v-if="lotteries_info != null && general_info != null"
        >
          <div class="banner-image-container">
            <img
              class="banner-image"
              alt="aside-banner"
              :src="lotteries_info.lotteries[type].image"
            />
          </div>
          <div class="banner-amount pt-3 black--text">
            {{
              general_info.lotteries[type].next_draw.amount == 0
                ? $t("message.notAvailable")
                : $formatMoneyToWord(
                    general_info.lotteries[type].next_draw.amount,
                    "US$",
                    ""
                  )
            }}
          </div>
          <emb-countdown
            class="pb-2 grey--text"
            :deadline="
              $removeHours(
                general_info.lotteries[type].next_draw.date.toDate(),
                general_info.limits.stop_purchase_hour
              )
            "
          >
          </emb-countdown>
          <div
            class="pb-2"
            v-if="general_info.lotteries[type].next_draw.amount != 0"
          >
            <v-btn class="accent"> {{ $t("message.play") }} </v-btn>
          </div>
        </div>
      </router-link>
    </div>
    <div v-else>
      <div
        class="emb-card"
        v-if="lotteries_info != null && general_info != null"
      >
        <div class="banner-image-container">
          <img
            class="banner-image"
            alt="aside-banner"
            :src="lotteries_info.lotteries[type].image"
          />
        </div>
        <div class="banner-amount pt-3 black--text">
          {{
            general_info.lotteries[type].next_draw.amount == 0
              ? $t("message.notAvailable")
              : $formatMoneyToWord(
                  general_info.lotteries[type].next_draw.amount,
                  "US$",
                  ""
                )
          }}
        </div>
        <div class="pa-2 grey--text">
          {{ $t("message.banner.tooLate") }}
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.banner-amount {
  font-size: 2em;
}
.banner-image {
  display: block;
  max-width: 230px;
  max-height: 95px;
  width: auto;
  height: auto;
}
.banner-image-container {
  display: inline-block;
}
.banner-too-late {
  font-size: 0.5em;
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["lotteries_info"]),
  },
  props: ["type"],
  data() {
    return {
      can_buy: true,
      general_info: null,
    };
  },
  mounted() {
    this.$store.dispatch("fetch_general_info").then((info) => {
      this.general_info = info;
      this.$store
        .dispatch("can_buy", { type: this.type, root: this.$root })
        .then((can_buy) => {
          this.can_buy = can_buy;
        });
    });
  },
  methods: {},
};
</script>
