<template>
  <div class="emb-feature-wrap">
    <div class="container" v-if="info != null">
      <div class="title">
        <h3>{{ $t("message.winningNumbers") }}</h3>
      </div>
      <v-layout row wrap white ma-1 emb-card>
        <v-flex
          xs12
          sm12
          md6
          lg6
          class="feature-item pa-6"
          v-for="(lottery_object, key) in info.lotteries"
          :key="key"
        >
          <div class="emb-feature-content layout align-center pa-md-4">
            <div class="thumb-wrap mb-4 mb-md-0">
              <img :src="img_mapping[key]" width="100" alt="feature image" />
            </div>
            <div class="features">
              <h5 class="font-weight-medium">
                {{ lottery_object.last_result.numbers }}
              </h5>
              <h6 class="inline-block">
                {{ multiplier_mapping[key] }}:
                {{ lottery_object.last_result.multiplier }}
              </h6>
              <span class="grey--text"
                >{{ $t("message.date") }}:
                {{ lottery_object.last_result.date }}</span
              >
            </div>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<style scoped>
.title {
  text-align: center;
}
</style>
<script>
export default {
  props: ["data"],
  data() {
    return {
      info: null,
      img_mapping: {
        megamillions: "/static/images/megamillions_mini.png",
        powerball: "/static/images/powerball_mini.png",
      },
      multiplier_mapping: {
        megamillions: "Megaplier",
        powerball: "Powerplay",
      },
    };
  },
  mounted() {
    this.$store.dispatch("fetch_general_info").then((info) => {
      this.info = info;
    });
  },
};
</script>
