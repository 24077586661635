export default [
    {
        image: "/static/images/money.png",
        subTitle: "100% OF YOUR WINNINGS",
        title: "You keep 100% of your earnings",
        desc: "*No catch, no small characters ;)"
    },
    {
        image: "/static/images/safe.png",
        subTitle: "YOUR TICKET IN SAFETY",
        title: "We keep your ticket safe",
        desc: ""
    },
    {
        image: "/static/images/travel.png",
        subTitle: "YOUR ARE OUR GUEST",
        title: "If you win, we paid the plane ticket!",
        desc: "*For a winning over US$5000"
    }
]