export default[
   {
      image: "/static/images/user-1.jpg",
      user: "Jimmy Farris",
      desc:
        "I love the way it has delivered to me and after using ,i just wanna say one word that is awesome ",
      position: "CEO , Dellec Info"
   },
   {
      image: "/static/images/user-2.jpg",
      user: " Farris",
      desc:
        "This is exactly what our business has been lacking. After using embryo my business skyrocketed!",
      position: "CEO , Dellec Info"
   },
   {
      image: "/static/images/user-3.jpg",
      user: "Willie Davis",
      desc:
        "Embryo is the most valuable business resource we have EVER purchased. Embryo saved my business",
      position: "CEO , Dellec Info"
   },
   {
      image: "/static/images/user-3.jpg",
      user: "Rivish Standol",
      desc:
        "I didn't even need training. I don't know what else to say. I am so pleased with this products!",
      position: "CEO , Dellec Info"
   },
   {
      image: "/static/images/user-5.jpg",
      user: "Fischia Samules",
      desc:
        "We're loving it. You won't regret it. I am really satisfied with my embryo.I STRONGLY recommend",
      position: "CEO , Dellec Info"
   }
]