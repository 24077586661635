<template>
  <div class="home-banner-wrap">
    <slick ref="carousel" :options="slickOptions" v-if="general_info != null">
      <div v-for="(sliderItem, key) in get_data()" :key="key">
        <div class="banner-slide">
          <div class="banner_container">
            <img
              :src="getImage(sliderItem)"
              alt="slide-item"
              width="1123"
              height="660"
            />
            <div
              class="banner_amount"
              v-if="sliderItem.type == 'lottery' && sliderItem.can_buy == true"
            >
              <div class="banner_amount_text">
                {{
                  general_info.lotteries[sliderItem.lottery_name].next_draw
                    .amount == 0
                    ? $t("message.notAvailable")
                    : $formatMoneyToWord(
                        general_info.lotteries[sliderItem.lottery_name]
                          .next_draw.amount,
                        "US$",
                        ""
                      )
                }}
              </div>
              <div class="banner_contdown">
                <emb-countdown
                  :deadline="
                    $removeHours(
                      general_info.lotteries[
                        sliderItem.lottery_name
                      ].next_draw.date.toDate(),
                      general_info.limits.stop_purchase_hour
                    )
                  "
                >
                </emb-countdown>
              </div>
            </div>
            <!-- <div class="banner_amount" v-else-if="sliderItem.type == 'lottery'">
              <div class="banner_timeout_text">
                Il est trop tard pour acheter un billet pour le tirage du 24
                novembre mais revenez
              </div>
            </div> -->
            <div v-else-if="sliderItem.type == 'freelottery'">
              <div class="banner_free_container">
                <div class="banner_free">{{ $t(sliderItem.heading) }}</div>
                <div class="banner_free_desc">
                  {{ $t(sliderItem.subHeading) }}
                </div>
              </div>
              <div class="banner_free_image_container">
                <div
                  class="banner_free_image"
                  v-for="(lottery, lottery_key) in lotteries_info.lotteries"
                  :key="lottery_key"
                >
                  <img :src="lottery.image" alt="lottery" width="300" />
                </div>
              </div>
            </div>
            <div v-else-if="sliderItem.type == 'info'">
              <div class="banner_about_container">
                <div class="banner_about font-weight-bold">
                  {{ $t(sliderItem.heading) }}
                </div>
                <div class="banner_about_desc">
                  {{ $t(sliderItem.subHeading) }}
                </div>
                <div class="d-flex justify-center pt-3">
                  <router-link :to="sliderItem.route">
                    <v-btn class="accent">{{
                      $t("message.banner.moreDetails")
                    }}</v-btn>
                  </router-link>
                </div>
              </div>
            </div>
            <div v-else-if="sliderItem.type == 'info2'">
              <div class="banner_info2_container">
                <div class="d-flex justify-center pt-3 banner_info2">
                  {{ $t(sliderItem.heading) }}
                </div>
              </div>
            </div>
            <div v-else-if="sliderItem.type == 'info_cc'">
              <div class="banner_about_container">
                <div class="banner_cc font-weight-bold">
                  {{ $t(sliderItem.heading) }}
                </div>
                <div class="banner_cc_desc">
                  {{ $t(sliderItem.subHeading) }}
                </div>
              </div>
            </div>
            <div v-else-if="sliderItem.type == 'image'"></div>
            <div
              class="banner_button"
              v-if="
                (sliderItem.can_buy &&
                  general_info.lotteries[sliderItem.lottery_name].next_draw
                    .amount != 0) ||
                sliderItem.type == 'freelottery'
              "
            >
              <router-link :to="sliderItem.route">
                <v-btn class="accent"> {{ $t("message.play") }} </v-btn>
              </router-link>
            </div>
            <div v-else-if="sliderItem.type == 'promo'">
              <div class="banner_button_left" >
                <v-btn class="accent" @click="showPromo"> {{ $t("message.banner.showOffers") }} </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slick>
    <promo-dialog ref="promoDialog">
    </promo-dialog>
  </div>
</template>

<style scoped>
.banner_container {
  position: relative;
}
.banner_amount {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px;
  border-radius: 5px;
  /* font-size: 30px; */
}
.banner_free_container {
  position: absolute;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  width: 40%;
  top: 20px;
  left: 6px;
}
.banner_about_container {
  position: absolute;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  width: 50%;
  top: 20px;
  left: 6px;
}
.banner_info2_container {
  position: absolute;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  bottom: 20px;
  left: 6px;
}
.banner_free {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5px;

  font-size: 4vw;
  color: black;
}
.banner_about {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5px;

  font-size: 4vw;
  color: #2b2a63;
}
.banner_info2 {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5px;

  font-size: 3vw;
  color: #000000;
}
.banner_cc {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5px;

  font-size: 3vw;
  color: #2b2a63;
}
.banner_free_desc {
  text-align: center;
  padding: 5px;
  font-size: min(3vw, 25px);
  color: black;
}
.banner_about_desc {
  text-align: center;
  padding: 5px;
  font-size: min(3vw, 25px);
  color: #2b2a63;
}
.banner_cc_desc {
  text-align: center;
  padding: 5px;
  font-size: min(3vw, 15px);
  color: #2b2a63;
}
.banner_amount_text {
  font-size: 7vw;
  font-weight: bold;
  color: black;
  /* -webkit-text-stroke: 2px black; width and color */
}
.banner_timeout_text {
  font-size: 3vw;
  font-weight: bold;
  color: black;
  /*-webkit-text-stroke: 2px black;*/ /* width and color */
}
.banner_free_image_container {
  position: absolute;
  width: 40%;
  top: 0px;
  right: 6px;
}
.banner_free_image {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
@media screen and (min-width: 1000px) {
  .banner_amount_text {
    font-size: 5vw;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 2px black; /* width and color */
  }
}
.banner_contdown {
  color: black;
  font-size: 3vw;
  text-align: center;
}
.banner_button {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.banner_button_left {
  position: absolute;
  bottom: 2vw;
  left: 10vw;
}
</style>

<script>
import Slick from "vue-slick";
import { mapGetters } from "vuex";
import PromoDialog from "Components/Widgets/PromoDialog.vue";


export default {
  computed: {
    ...mapGetters(["lotteries_info", "general_info"]),
  },
  props: ["data", "text"],
  components: { Slick, PromoDialog },
  data() {
    return {
      can_buy: true,
      lottery_info: null,
      lottery_live_info: null,
      slickOptions: {
        rtl: this.rtlLayout,
        slidesToShow: 1,
        infinite: true,
        swipe: true,
        autoplay: true,
        dots: false,
        arrows: false,
        pauseOnHover: true,
        autoplaySpeed: 8000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
            },
          },
        ],
      },
    };
  },
  watch: {
    "$store.state.generalInfo": function () {
      this.update_can_buy();
    },
  },
  methods: {
    get_data() {
      return this.data.filter(
        (item) => item.can_buy == undefined || item.can_buy == true
      );
    },
    update_can_buy() {
      if (this.general_info != null) {
        for (var dataItemIndex in this.data) {
          var dataItem = this.data[dataItemIndex];
          if (dataItem.type == "lottery") {
            var time_left = this.$root.$removeHours(
              this.general_info.lotteries[
                dataItem.lottery_name
              ].next_draw.date.toDate(),
              this.general_info.limits.stop_purchase_hour
            );
            if (Date.now() > time_left) {
              dataItem.can_buy = false;
            } else {
              dataItem.can_buy = true;
            }
          }
        }
      }
      setTimeout(this.update_can_buy, 1000);
    },
    getImage(item) {
      if (item.image != undefined) {
        return item.image;
      } else if (item.image_tr != undefined) {
        return this.$t("message.images." + item.image_tr);
      }
    },
    showPromo() {
      this.$refs.promoDialog.openDialog();
    },
    bannerClick(item) {
      if (item.type == "promo") {
        this.showPromo();
      }
    },
  },
  beforeMount() {
    this.update_can_buy();
  },
};
</script>

