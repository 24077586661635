export default {
   sectitle: "Deal of the day",
   subtitle: "Denim Pullover",
   metainfo: "45.00",
   metaainfo: "Now only",
   paragraph:
     "Suspendisse porttitor ornare ligula. Nam massa erat,fermentum dolor quis, maximus	ultrices diam. Aenean pellentesque	auctor elementum. Nunc vitae tortor	iaculis, mollis odio at, lacinia sapien. Mauris et leo sem. Curabitur sit amet enim nisi. Nunc placerat commodo sem,	sed maximus purus",
   productGallery: [
     "/static/images/men/1-item-a.jpg",
     "/static/images/men/1-item-c.jpg",
     "/static/images/men/1-item-b.jpg"
   ]
}