var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-banner-wrap"},[(_vm.general_info != null)?_c('slick',{ref:"carousel",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.get_data()),function(sliderItem,key){return _c('div',{key:key},[_c('div',{staticClass:"banner-slide"},[_c('div',{staticClass:"banner_container"},[_c('img',{attrs:{"src":_vm.getImage(sliderItem),"alt":"slide-item","width":"1123","height":"660"}}),(sliderItem.type == 'lottery' && sliderItem.can_buy == true)?_c('div',{staticClass:"banner_amount"},[_c('div',{staticClass:"banner_amount_text"},[_vm._v(" "+_vm._s(_vm.general_info.lotteries[sliderItem.lottery_name].next_draw .amount == 0 ? _vm.$t("message.notAvailable") : _vm.$formatMoneyToWord( _vm.general_info.lotteries[sliderItem.lottery_name] .next_draw.amount, "US$", "" ))+" ")]),_c('div',{staticClass:"banner_contdown"},[_c('emb-countdown',{attrs:{"deadline":_vm.$removeHours(
                    _vm.general_info.lotteries[
                      sliderItem.lottery_name
                    ].next_draw.date.toDate(),
                    _vm.general_info.limits.stop_purchase_hour
                  )}})],1)]):(sliderItem.type == 'freelottery')?_c('div',[_c('div',{staticClass:"banner_free_container"},[_c('div',{staticClass:"banner_free"},[_vm._v(_vm._s(_vm.$t(sliderItem.heading)))]),_c('div',{staticClass:"banner_free_desc"},[_vm._v(" "+_vm._s(_vm.$t(sliderItem.subHeading))+" ")])]),_c('div',{staticClass:"banner_free_image_container"},_vm._l((_vm.lotteries_info.lotteries),function(lottery,lottery_key){return _c('div',{key:lottery_key,staticClass:"banner_free_image"},[_c('img',{attrs:{"src":lottery.image,"alt":"lottery","width":"300"}})])}),0)]):(sliderItem.type == 'info')?_c('div',[_c('div',{staticClass:"banner_about_container"},[_c('div',{staticClass:"banner_about font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(sliderItem.heading))+" ")]),_c('div',{staticClass:"banner_about_desc"},[_vm._v(" "+_vm._s(_vm.$t(sliderItem.subHeading))+" ")]),_c('div',{staticClass:"d-flex justify-center pt-3"},[_c('router-link',{attrs:{"to":sliderItem.route}},[_c('v-btn',{staticClass:"accent"},[_vm._v(_vm._s(_vm.$t("message.banner.moreDetails")))])],1)],1)])]):(sliderItem.type == 'info2')?_c('div',[_c('div',{staticClass:"banner_info2_container"},[_c('div',{staticClass:"d-flex justify-center pt-3 banner_info2"},[_vm._v(" "+_vm._s(_vm.$t(sliderItem.heading))+" ")])])]):(sliderItem.type == 'info_cc')?_c('div',[_c('div',{staticClass:"banner_about_container"},[_c('div',{staticClass:"banner_cc font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(sliderItem.heading))+" ")]),_c('div',{staticClass:"banner_cc_desc"},[_vm._v(" "+_vm._s(_vm.$t(sliderItem.subHeading))+" ")])])]):(sliderItem.type == 'image')?_c('div'):_vm._e(),(
              (sliderItem.can_buy &&
                _vm.general_info.lotteries[sliderItem.lottery_name].next_draw
                  .amount != 0) ||
              sliderItem.type == 'freelottery'
            )?_c('div',{staticClass:"banner_button"},[_c('router-link',{attrs:{"to":sliderItem.route}},[_c('v-btn',{staticClass:"accent"},[_vm._v(" "+_vm._s(_vm.$t("message.play"))+" ")])],1)],1):(sliderItem.type == 'promo')?_c('div',[_c('div',{staticClass:"banner_button_left"},[_c('v-btn',{staticClass:"accent",on:{"click":_vm.showPromo}},[_vm._v(" "+_vm._s(_vm.$t("message.banner.showOffers"))+" ")])],1)]):_vm._e()])])])}),0):_vm._e(),_c('promo-dialog',{ref:"promoDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }